<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <loading-notification :show="loading || verifying" />
        <error-notification :show="assignedError">
          <span>{{ $t('notifications.auth.region_assigned') }}</span>
        </error-notification>
      </div>
      <div class="row">
        <div class="flex xs12 md4">
          <va-input
            color="info"
            v-model="form.name.value"
            :disabled="loading"
            :label="$t('layout.form.nameInput')"
            :error="!!form.name.errors.length"
            :error-messages="translatedErrors(form.name.errors)"
            @blur="validate('name')"
          >
            <va-popover
              :message="$t('layout.translation')"
              placement="top"
              slot="append"
            >
              <va-button
                flat
                small
                type="button"
                color="primary"
                style="margin-right: 0;"
                icon="entypo entypo-language"
                @click="openTranslator"
              />
            </va-popover>
          </va-input>
        </div>
        <div class="flex xs12 md4">
          <va-input
            color="info"
            :value="$t(form.name.value)"
            :disabled="true"
            :label="$t('layout.form.name_translated_input')"
          />
        </div>
        <div class="flex xs12 md4">
          <va-select
            v-model="selectedManager"
            :label="$t('regions.inputs.managerInput')"
            :options="managersList"
            :loading="isLoading.managers"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
          <!--
          <div class="input-group vue-multiselect">
            <label class="control-label">{{ $t('regions.inputs.managerInput') }}</label>
            <vue-multiselect
              v-model="selectedManager"
              :searchable="true"
              :allow-empty="true"
              :options="managersList"
              :loading="isLoading.managers"
              :internal-search="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              :disabled="loading"
              @input="checkManager"
              name="id"
              track-by="id"
              label="name"
              open-direction="bottom"
              :placeholder="$t('extra.search')"
              :select-label="$t('extra.select.enter_to_select')"
            >
              <p slot="noResult">
                {{ $t('extra.select.no_results') }}
              </p>
              <p slot="noOptions">
                {{ $t('extra.select.no_options') }}
              </p>
            </vue-multiselect>
          </div>
          -->
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading || verifying"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >
              {{ $t('layout.form.save') }}
            </text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal
        :edit="true"
        :path="form.name.value"
        translation-type="both"
        @saved="closeTranslatorModal"
      />
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')

export default {
  name: 'regions-form',
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    region: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  components: {
    TranslatorModal,
  },
  mixins: [validatorMixin],
  data () {
    return {
      translatorModal: false,
      verifying: false,
      assignedError: false,
      isLoading: {
        managers: false,
      },
      managersList: [],
      selectedManager: '',
      form: {
        name: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
    isNew () {
      return this.region.id === 0
    },
  },
  watch: {
    region (val) {
      this.setRegion(val)
    },
    async currentLocale (val) {
      await this.$nextTick()
      this.validateAll()
    },
    selectedManager (val) {
      this.checkManager(val)
    },
  },
  created () {
    this.initialData()
    this.asyncFindManagers('2')
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    openTranslator () {
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    async setRegion (val) {
      this.setFormData(val)
      if (val.manager) {
        this.selectedManager = val.manager
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.region) {
        this.setRegion(this.region)
      }
    },
    asyncFindManagers (query) {
      this.asyncUsers(query, 'managers', 'users')
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?group=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async checkManager (user) {
      if (this.region && this.region.manager) {
        if (user.id === this.region.manager.id) return
      }
      this.verifying = true

      let response = false
      try {
        response = await this.$http.get(`users/ownership/${user.id}?regions`)
      } catch (e) {
        // console.log('Cant verify ownership', e)
        this.verifying = false
        return
      }

      this.assignedError = response.data.data
      this.verifying = false
    },
    async submit () {
      if (!this.isNew && this.selectedManager === null) {
        this.showToast(this.$t('notifications.auth.manager_needed'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }

      this.validateAll()
      if (!this.formReady) return

      const region = this.getFormData(this.region)
      if (this.selectedManager) {
        region.manager_id = this.selectedManager.id
        delete region.manager
      }

      if (region.countries) {
        delete region.countries
      }

      this.assignedError = false
      this.$emit('submit', region)
    },
  },
}
</script>
